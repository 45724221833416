
import { ElConfigProvider } from 'element-plus';
import ptBr from 'element-plus/lib/locale/lang/pt-br';
import { computed, defineComponent, onBeforeUnmount, onMounted, reactive, ref, watch } from "vue";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import { TODAY } from "@/core/data/events";
import VisualizarServicoModal from "@/views/agenda-virtual/components/modais/servicos/VisualizarServicoModal.vue";
import { ContentLoader } from 'vue-content-loader';
import { Modal } from "bootstrap";
import moment, { Moment } from "moment";
import { getServicosAgendados } from '@/services/AgendaService';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import { config } from "@/views/agenda-virtual/ConfigEvents";
import useEmitter from '@/composables/Emmiter';
import AuthService from "@/services/AuthService";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import {changeStoppedDayUpdate} from '@/views/agenda-virtual/utils/changeStopDayUtils'

export default defineComponent({
  name: "agenda-virtual-exibir-camp",
  components: {
    FullCalendar,
    VisualizarServicoModal,
    ContentLoader,
    ElConfigProvider,
  },

  setup() {
    const store = useStore();
    const emitter = useEmitter();
    const userInfo = AuthService.getUsuarioAuth();
    const codNivel = userInfo.codNivel;
    const isUserActive = ref<any>(false);
    const refCalendar:any = ref(null);
    const eventInfo:any = ref();
    const concessionariaInfo:any = ref({});
    const loading:any = ref(true);
    const resources:any = ref([]);
    let events:any = ref([]);
    const tecnicos:any = ref([]);
    const consultores:any = ref([]);
    const dataMesAtual = moment().startOf("day").toISOString();
    const dateCalendarFilter = ref(dataMesAtual);
    const timeNow = ref(moment().format("HH:mm:ss"));
    const usaChecklist = ref('')
    const countClickPrevNext = ref(0)
        
    async function horariosQuadro() {
      const concessionaria = store.getters.concessionariaSelectedInfo
      usaChecklist.value = concessionaria.usaChecklist
      if(!(Object.keys(concessionaria).length === 0)){
        calendarOptions.value.slotMinTime = moment(concessionaria.horaAbertura, "H").format("HH:mm:ss")
        calendarOptions.value.slotMaxTime = moment(concessionaria.horaFechamento, "H").format("HH:mm:ss")
        calendarOptions.value.businessHours.startTime = moment(concessionaria.horaAbertura, "H").format("HH:mm")
        calendarOptions.value.businessHours.endTime = moment(concessionaria.horaFechamento, "H").format("HH:mm")
      }
    }

    const calendarOptions = ref({
      schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
      plugins: [resourceTimelinePlugin, dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin, resourceTimeGridPlugin ],
      headerToolbar: {
        left: "",
        center: "prev title next",
        right: ""
        // right: "dayGridMonth,timeGridWeek,timeGridDay",
      },
      customButtons: {
        prev: {
          click: () => {
            refCalendar.value.getApi().prev();
            countClickPrevNext.value--
            controlaFiltro();
          }
        },
        next: {
          click: () => {
            refCalendar.value.getApi().next();
            countClickPrevNext.value++
            controlaFiltro();
          }
        },
      },
      nowIndicator: true,
      businessHours: {
        // days of week. an array of zero-based day of week integers (0=Sunday)
        daysOfWeek: [1, 2, 3, 4, 5, 6], // Monday - Thursday
        
        startTime: '08:00', // a start time (10am in this example)
        endTime: '18:00', // an end time (6pm in this example)
      },
      resourceOrder: 'nome',
      slotMinTime: '08:00:00',
      slotMaxTime: '18:00:00',
      allDaySlot: false,
      locale: "pt-br",
      scrollTime: moment().subtract(2.25, "hours").format("HH:mm"),
      initialDate: TODAY,
      snapDuration: "00:15",

      initialView: 'resourceTimelineDay',
      eventColor: 'blue',
      eventBorderColor: 'blue',
      height: "100%",
      contentHeight: "auto",
      eventBackgroundColor: 'blue',
      events: events.value,
      resources: resources.value,
      eventDurationEditable: false,
      resourceAreaWidth: '10%',
      resourceAreaColumns: [
        // {
        //   field: 'num',
        //   headerContent: 'Nº',
        //   width: 10,
        //   group: true,
        // },
        {
          field: 'title',
          headerContent: 'Técnico',
          width: 60,
          cellContent: function (info) {
            let propriedades;
            if(info.fieldValue){
              propriedades = JSON.parse(info.fieldValue)
            } else if(info.groupValue){
              propriedades = JSON.parse(info.groupValue)
            }

            const html = `<div class="calendar-image-camp-serv"><img src="${propriedades.imagem}"><div class="calendar-field-camp-serv">${trataNome(propriedades.nome)}</div></div>`
            return { html };
          },
          group: true,
        },
        // {
        //   field: 'servico',
        //   headerContent: 'Serviço',
        //   width: 25,
        //   group: true,
        // },
        {
          field: 'status',
          headerContent: '',
          width: 40,
        },
      ],
      views: {
        resourceTimelineDay: { 
          buttonText: "dia",
        },
        // timeGridWeek: { buttonText: "semana" },
        // dayGridMonth: { buttonText: "mês" },
      },
      editable: false,
      dayMaxEvents: true, // allow "more" link when too many events
      slotLabelFormat: {
          hour: '2-digit',
          minute: '2-digit',
          omitZeroMinute: false,
          meridiem: 'short'
      },
      eventClick: (info) => openVisualizarServicoModal(info),
      eventContent: function (info, createElement) {
        const start = moment(info.event.start);
        const end = moment(info.event.end);

        const eventInfo = info.event;

        const propriedades = eventInfo.extendedProps;
        let htmlContent =  "";
        let htmlFooter =  "";
        let htmlWait =  "";
        let htmlDuration =  "";
        
        const duration = moment.duration(end.diff(start)).asMinutes();

        const modeloTratado = propriedades.allInfo.modelo ? propriedades.allInfo.modelo.replace("AUDI ", "") : "";

        if(propriedades.hasContent){
          htmlContent = `
            <div class="event-content d-flex flex-column justify-content-end fs-6 px-1" style="line-height: 23px">
              <div class="text-overflow-visible w-100 d-flex justify-content-between">
                <div>${propriedades.allInfo.osAberta}</div> 
                <div>${ usaChecklist.value == '147' ?  propriedades.prisma : '' }</div>
              </div>
              <div class="text-truncate w-100">${modeloTratado}</div>
                <div style="${propriedades.placa ? '' : 'direction: rtl'}" class="w-100 ${propriedades.placa ? 'text-overflow-visible': 'text-truncate'}">${propriedades.placa ? propriedades.placa : propriedades.chassi}</div>
            </div>`;
          // htmlWait =  `<div class="event-wait">${propriedades.aguarda ? '<div class="wait-indicator"></div>' : ''}</div>`
        }

        if(propriedades.hasFooter){
          let classPercentual = "background_indicadores_1";
          let progressBackground = "#F5F8FA";
          const greenList = [3, 4, 6];

          if(propriedades.allInfo.status == 5){
            classPercentual = "background_indicadores_3";
          } else if (end.diff(moment()) <= 0 && !greenList.includes(propriedades.allInfo.status)){
            classPercentual = "background_indicadores_4";
            progressBackground = "#F64E6040";
          }


          let startServiceTime = moment(propriedades.allInfo.dataInicioServicoExecucao, "YYYY-MM-DD HH:mm:ss")
          let durationStart = moment.duration(moment().diff(startServiceTime)).asMinutes();
          if(propriedades.allInfo.status == 5 && propriedades.allInfo.dataFinalServicoExecucao){
            let endServiceTime = moment(propriedades.allInfo.dataFinalServicoExecucao, "YYYY-MM-DD HH:mm:ss")
            durationStart = moment.duration(endServiceTime.diff(startServiceTime)).asMinutes();
          }
          let auxPercentual = propriedades.allInfo.percentualRealizado ?? 0;

          if(propriedades.allInfo.status == 2 && propriedades.allInfo.percentualRealizado < 90){
            let auxDurationPercentual = durationStart * 100 / duration;
            auxPercentual = auxPercentual > auxDurationPercentual ? auxPercentual : auxDurationPercentual;
            
            if(auxPercentual > 90){
              auxPercentual = 90;
            } 
          }
  
          htmlFooter = `
          <div class="event-footer fs-6 flex-column">
            <div class="progress h-6px w-100" style="background-color: ${progressBackground}">
              <div class="progress-bar ${classPercentual}" role="progressbar" style="width: ${auxPercentual}%" aria-valuemin="0"aria-valuenow="50" aria-valuemax="100"></div>            </div>
            </div>
          </div>`;
        }

        if(propriedades.showDuration && duration > 15){
          htmlDuration =  `- ${duration}m`;
        }

        const ableOpacity = propriedades.allInfo.codTipoServico < 9 && (propriedades.allInfo.status != 3 && propriedades.allInfo.status != 4) && !propriedades.allInfo.osAberta;

        const html = `
        <div class="event fs-6" style="${!propriedades.hasContent ? 'background-color:' + propriedades.bgColor : 'background-color: white'}; ${ableOpacity ? 'filter: opacity(0.5)' : ''}">
          <div class="event-header" style="color: ${propriedades.headerTextColor}; background-color: ${propriedades.bgColor}">
            <span class="event-title text-truncate ${duration <= 15 ? 'event-title-min' : ''} ${propriedades.allInfo.codTipoServico > 8 ? 'text-truncate' : '' }" style="color: ${propriedades.headerTextColor}">
              ${eventInfo.title} ${htmlDuration}
            </span>
            ${htmlWait}
          </div>
          ${htmlContent}
          ${htmlFooter}
        </div>`;
        return { html };
      }
    });

    const refIntervals = reactive({
      baseAgendamento: 0,
      baseServicos: 0,
      timeNow: 0
    });

    const lastUpdatedServicos:any = ref(null);

    // LIFECYCLE HOOKS
    onMounted(() => {
      horariosQuadro()
      concessionariaInfo.value = store.getters.concessionariaSelectedInfo;      

      if(store.getters.consultoresList.length > 0){
        consultores.value = store.getters.consultoresList;
      }

      if(store.getters.mecanicosList.length > 0){
        tecnicos.value = store.getters.mecanicosList;
        trataMecanicos();
      }
      
      startPage();
      // EMITTER HOOKS
      emitter.on("update-agenda-servicos", () => {
        startPage();
      });

      emitter.on("agenda-clear-interval", () => {
        clearAllSetInterval();
      });

      emitter.on("update-calendar", () => {
        controlaFiltro();
      });

      refIntervals.timeNow = setInterval(() => {
        timeNow.value = moment().format("HH:mm:ss");        
      }, 1000);
    });

    store.watch(()=>store.getters.concessionariasList , ()=> {
        if(store.getters.concessionariasList) horariosQuadro()
      })

    onBeforeUnmount(() => {
      if(refIntervals.timeNow){
        clearInterval(refIntervals.timeNow);
      }
      clearAllSetInterval();

      emitter.off("update-calendar");
      emitter.off("update-agenda-servicos");
      emitter.off("agenda-clear-interval");
    });

    // WATCH HOOKS
    store.watch(() => store.getters.concessionariaSelectedInfo, () => {
      concessionariaInfo.value = store.getters.concessionariaSelectedInfo;
    }, { deep: true });

    store.watch(() => store.getters.baseServicosList, () => {
      const dateFilter = moment(refCalendar.value?.getApi()?.getDate() ?? undefined);

      if(dateFilter.isSame(moment(), "day") && store.getters.baseServicosList.length > 0){
        trataServicos(store.getters.baseServicosList);
      }
    }, { deep: true });

    store.watch(() => store.getters.consultoresList, () => {
      consultores.value = store.getters.consultoresList;
    }, { deep: true });

    store.watch(() => store.getters.mecanicosList, () => {
      tecnicos.value = store.getters.mecanicosList;

      trataMecanicos();
    }, { deep: true });

    // FUNCTIONS
    function startPage(){
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 500);
      inactivityTime();

      const auxDateSelected = moment(refCalendar.value?.getApi()?.getDate() ?? undefined);
      const auxUpdateTimeServico = store.getters.refLastUpdatedTime.lastBaseServicos;
      const auxUpdateTimeAgendamento = store.getters.refLastUpdatedTime.lastBaseAgendamentos;

      clearAllSetInterval();

      if(auxDateSelected.isSame(moment(), "day")){
        if(!auxUpdateTimeServico || moment().isAfter(auxUpdateTimeServico.clone().add(60, "seconds"))){
          store.dispatch(Actions.SET_BASE_SERVICOS).then(() => {
            loading.value = false;
          });
        } else {
          trataServicos(store.getters.baseServicosList);
        }

        refIntervals.baseServicos = setInterval(() => {
          store.dispatch(Actions.SET_BASE_SERVICOS);
        }, 90000);

        //  TODO Adicionar a lógica quando o período for diferente ao implementar base de OS abertas
        // if(!auxUpdateTimeAgendamento || moment().isAfter(auxUpdateTimeAgendamento.clone().add(210, "seconds"))){
        //   // auxUpdateTimeAgendamento.value = moment();
        //   store.dispatch(Actions.SET_BASE_AGENDAMENTOS);
        // }

        // refIntervals.baseAgendamento = setInterval(() => {
        //   store.dispatch(Actions.SET_BASE_AGENDAMENTOS);
        // }, 300000);
      } else {
        if(!lastUpdatedServicos.value || moment().isAfter(lastUpdatedServicos.value.clone().add(60, "seconds"))) {
          controlaFiltro();
        }
      }
    };

    function clearAllSetInterval(){
      clearInterval(refIntervals.baseServicos);
    };

    function changeCalendarDate(date){
      if(date){
        refCalendar.value.getApi().gotoDate(date);

        controlaFiltro();
      }
    };

    async function trataMecanicos(){
      while(resources.value.length) {
        resources.value.pop();
      }

      tecnicos.value.forEach((tecnico, index) => {
        const resource = {
          id: `${tecnico.codMecanico}_aplano`,
          title: JSON.stringify({
            nome: tecnico.nome,
            imagem: tecnico.imagem,
          }),
          nome: tecnico.nome,
          servico: "",
          num: index + 1,
          eventColor: "none",
          status: "Plano"
        };
        const resourceB = {
          id: `${tecnico.codMecanico}_execucao`,
          title: JSON.stringify({
            nome: tecnico.nome,
            imagem: tecnico.imagem,
          }),
          servico: "",
          num: index + 1,
          eventColor: "none",
          status: "Início/Fim"
        };

        resources.value.push(resource);
        resources.value.push(resourceB);
      });
    };

    function clearCalendar(){
      const eventsCalendar = refCalendar.value?.getApi()?.getEvents() ?? [];
      eventsCalendar.forEach(event => {
        event.remove();
      });
    };

    function removeAcentos(name, toLowerCase = false) {
      if(!name){
        return "";
      }
      const stringTratado = name.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
      return toLowerCase ? stringTratado.toLowerCase() : stringTratado;
    };

    async function buscaServicos(showLoading){
      const dateFilter = moment(refCalendar.value?.getApi()?.getDate() ?? undefined);
      lastUpdatedServicos.value = dateFilter.clone();

      try{
        if(showLoading){
          loading.value = true;
        }

        if(dateFilter.isSame(moment(), "day")){
          clearAllSetInterval();
          await store.dispatch(Actions.SET_BASE_SERVICOS);

          refIntervals.baseServicos = setInterval(() => {
            store.dispatch(Actions.SET_BASE_SERVICOS);
          }, 90000);
        } else {
          const response = await getServicosAgendados(concessionariaInfo.value.codConcessionaria, dateFilter.format("YYYY-MM-DD"));

          if(response.length > 0){
            trataServicos(response);
          }
        }
      } finally {
        if(showLoading){
          loading.value = false;
        }
      }
    }
    
    async function controlaFiltro() {
      const dateFilter = moment(refCalendar.value?.getApi()?.getDate() ?? undefined);

      clearAllSetInterval();

      if(dateFilter.isSame(moment(), "day")){
        loading.value = true;
        await store.dispatch(Actions.SET_BASE_SERVICOS);
        loading.value = false;

        refIntervals.baseServicos = setInterval(() => {
          store.dispatch(Actions.SET_BASE_SERVICOS);
        }, 90000);
      } else {
        buscaServicos(true);

        refIntervals.baseServicos = setInterval(() => {
          buscaServicos(false);
          changeStoppedDayUpdate(countClickPrevNext.value,refCalendar.value,controlaFiltro)
        }, 90000);
      }
    }

    function trataServicos(servicos){
      try {
        clearCalendar();

        servicos.forEach((servico) => {
          const eventConfig = config.events[servico.codTipoServico - 1];

          let newEvent;

          if(servico.tempoAdicional){
            const end = moment(servico.dataFinalServico.replace(" ", "T"), "YYYY-MM-DD HH:mm:ss");
            servico.endWithout = end.subtract(servico.tempoAdicional, "minutes").format("YYYY-MM-DD HH:mm:ss");
          } else {
            servico.endWithout = servico.dataInicioServico.replace(" ", "T");
          }

          let statusText = "";

          if(servico.status == 1){
            statusText = "_aplano";
          } else if(servico.status >= 2) {
            statusText = "_execucao";
          }

          if(servico.codTipoServico < 9){
            const defaultCor =  store.getters.coresAgenda[consultores.value.length] ?? '#d3d3d3';
            let bgColor = consultores.value.find(consultor => removeAcentos(consultor.intConsultor, true) == removeAcentos(servico.consultorAgendado, true))?.corAgenda;
            if(!bgColor){
              bgColor = consultores.value.find(consultor => removeAcentos(consultor.nome, true) == removeAcentos(servico.consultorAgendado, true))?.corAgenda;
            }
            bgColor = bgColor ?? defaultCor;

            newEvent = {
              id: servico.codServico,
              resourceId: `${servico.codTecnico.toString()}${statusText}`,
              title: eventConfig.title,
              start: servico.dataInicioServico.replace(" ", "T"),
              end: servico.dataFinalServico.replace(" ", "T"),
              extendedProps: {                
                showDuration: eventConfig.extendedProps.showDuration,
                aguarda: servico.aguarda == "1" ? true : false,
                hasContent: eventConfig.extendedProps.hasContent,
                hasFooter: eventConfig.extendedProps.hasFooter,
                cliente: trataNome(servico.cliente),
                consultor: trataNome(servico.consultorAgendado),
                recepcao: servico.dataRecepcao.replace(" ", "T"), 
                entrega: servico.dataEntrega.replace(" ", "T"),
                prisma: servico.prisma ?? '',
                placa: servico.placa,
                chassi: servico.chassi ?? "",
                bgColor,
                headerTextColor: "#ffffff",
                allInfo: servico,
              }
            };
          } else if(servico.codTipoServico == 9){
            newEvent = {
              id: servico.codServico,
              resourceId: `${servico.codTecnico.toString()}${statusText}`,
              title: eventConfig.title,
              daysOfWeek: [1, 2, 3, 4, 5, 6],
              startTime: moment(servico.dataInicioServico, "YYYY-MM-DD HH:mm:ss").format("HH:mm"),
              endTime: moment(servico.dataFinalServico, "YYYY-MM-DD HH:mm:ss").format("HH:mm"),
              extendedProps: {
                showDuration: eventConfig.extendedProps.showDuration,
                hasContent: eventConfig.extendedProps.hasContent,
                hasFooter: eventConfig.extendedProps.hasFooter,
                bgColor: "darkgray",
                prisma: servico.prisma ?? '',
                headerTextColor: eventConfig.extendedProps.headerTextColor,
                allInfo: servico,
              }
            };
          } else if(servico.codTipoServico == 10 || servico.codTipoServico == 11){
            newEvent = {
              id: servico.codServico,
              resourceId: `${servico.codTecnico.toString()}${statusText}`,
              title: eventConfig.title,
              start:  moment(servico.dataInicioServico, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD"),
              end: moment(servico.dataFinalServico, "YYYY-MM-DD HH:mm:ss").endOf("day").format("YYYY-MM-DD HH:mm:ss"),
              extendedProps: {
                showDuration: eventConfig.extendedProps.showDuration,
                hasContent: eventConfig.extendedProps.hasContent,
                hasFooter: eventConfig.extendedProps.hasFooter,
                bgColor: "darkgray",
                prisma: servico.prisma ?? '',
                headerTextColor: eventConfig.extendedProps.headerTextColor,
                allInfo: servico,
              }
            };
          } else if(servico.codTipoServico == 12){
            newEvent = {
              id: servico.codServico,
              resourceId: `${servico.codTecnico.toString()}${statusText}`,
              title: servico.observacao,
              start: servico.dataInicioServico.replace(" ", "T"),
              end: servico.dataFinalServico.replace(" ", "T"),
              extendedProps: {
                showDuration: eventConfig.extendedProps.showDuration,
                hasContent: eventConfig.extendedProps.hasContent,
                hasFooter: eventConfig.extendedProps.hasFooter,
                prisma: servico.prisma ?? '',
                bgColor: "darkgray",
                headerTextColor: eventConfig.extendedProps.headerTextColor,
                allInfo: servico,
              }
            };
          }

          refCalendar.value.getApi().addEvent(newEvent);
        });

        const calendarioDataAtual = moment().isSame(moment(refCalendar.value?.getApi()?.getDate() ?? undefined), 'day');
        if(calendarioDataAtual && !isUserActive.value){
          refCalendar.value?.getApi()?.scrollToTime(moment().subtract(2.25, "hours").format("HH:mm"));
        }
      } finally {
        loading.value = false;
        refCalendar.value?.getApi()?.updateSize();
      }
    }

    function calendarFullscreen() {
      if(document.fullscreenElement){
        document.exitFullscreen();
        return
      } 
      let elem:any = document.querySelector("#card-calendar-camp-servicos");
      if (elem.requestFullscreen) {
          elem.requestFullscreen({ navigationUI: "show" });
      } else if (elem.mozRequestFullScreen) { /* Firefox */
          elem.mozRequestFullScreen({ navigationUI: "show" });
      } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
          elem.webkitRequestFullscreen({ navigationUI: "show" });
      } else if (elem.msRequestFullscreen) { /* IE/Edge */
          elem.msRequestFullscreen({ navigationUI: "show" });
      }
    };

    function openVisualizarServicoModal(info) {
      if(document.fullscreenElement){
        document.exitFullscreen();
      } 
      eventInfo.value = info.event?.extendedProps?.allInfo;
      const modal = new Modal(document.getElementById("kt_modal_event_info_3"));
      modal.show();
    };

    function inactivityTime () {
      let time;
      // reset timer
      window.onload = resetTimer;
      document.onmousemove = resetTimer;
      document.onkeydown = resetTimer;
      function doSomething() {
        isUserActive.value = false;
      }
      function resetTimer() {
        isUserActive.value = true;
        clearTimeout(time);
        time = setTimeout(doSomething, 5000)
      }
    };

    function trataNome(nome){
      if(!nome || nome == "undefined" || nome == undefined){
        return "";
      }
      
      const arrayNomes = nome.split(" ");
      const nomeTratado = `${arrayNomes[0]}` 

      return nomeTratado;
    }

    return {
      ptBr,
      tecnicos,
      calendarOptions,
      events,
      refCalendar,
      resources,
      eventInfo,
      calendarFullscreen,
      loading,
      concessionariaInfo,
      controlaFiltro,
      codNivel,
      consultores,
      timeNow,
      isUserActive,
      headerTitle: computed(() => store.getters.concessionariaSelectedInfo.nomeFantasia),
      dateCalendarFilter,
      changeCalendarDate,
    };
  },
});
